import * as React from "react";
import { Link } from "gatsby";
import { Parallax } from "@react-spring/parallax";
import { Themed } from "theme-ui";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider";
import {
  UpDown,
  UpDownWide,
} from "@lekoarts/gatsby-theme-cara/src/styles/animations";
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg";
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo";
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content";
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner";

const Privacy = () => (
  <Layout>
    <Seo title="プライバシーポリシー" />
    <Inner>
      <Themed.h1>プライバシーポリシー（個人情報保護方針）</Themed.h1>
      株式会社Minerva株式会社（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
      【１．事業者情報】 法人名：Minerva株式会社
      住所：京都府京都市上京区甲斐守町97 代表者：平井純
      【２．個人情報の取得方法】
      当社はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。
      お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。
      【３．個人情報の利用目的】
      取得した閲覧・購買履歴等の情報を分析し、ユーザー別に適した商品・サービスをお知らせするために利用します。また、取得した閲覧・購買履歴等の情報は、結果をスコア化した上で当該スコアを第三者へ提供します。
      【４．個人データを安全に管理するための措置】
      当社は個人情報を正確かつ最新の内容に保つよう努めます。また、個人情報保護規程を設け、現場での管理についても定期的に点検を行っています。
      【５．個人データの共同利用】
      当社は当社と提携・協力している第三者へユーザーの個人データの全ての情報の共同利用を行います。
      【６．個人データの第三者提供について】
      当社は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
      なお、当アプリの利用規約へ同意によって同意したものとします。
      【７．保有個人データの開示、訂正】
      当社は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。
      （各社請求方法を指定） 送付先住所 〒602-8061 京都府京都市上京区甲斐守町97
      Minerva株式会社　アプリお問い合わせ窓口
      【８．個人情報取り扱いに関する相談や苦情の連絡先】
      当社の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはお問い合わせフォームよりご連絡ください。
      【９．SSL（Secure Socket Layer）について】
      当社のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
      【１０．cookieについて】
      cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当社Webサイトを利用することができます。当社Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。
      お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
      【１１．プライバシーポリシーの制定日及び改定日】 制定：2022年4月27日
      【１２．免責事項】
      当社Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
      当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
      その他、利用規約に記載されいる免責事項に係る一切について、当プライバシーポリシーにおいても準用するものとする。
      【１3．リンク】
      当社Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
    </Inner>
  </Layout>
);

export default Privacy;
